import React from "react";
import ChevronLeft from "../../styles/icons/ChevronLeft";
import MenuBar from "../../styles/icons/MenuBar";
import styles from "./header.module.scss";
import IconButton from "../iconButton";
import { useNavigate, Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalSorage";
import PersonRounded from "../../styles/icons/PersonRounded";


     /*<IconButton>
          <MenuBar color="white" />          
        </IconButton>*/
const Header = ({ showMenuBar = true }) => {
  const navigate = useNavigate();
  const [userToken] = useLocalStorage("user");
  const navigateBack = () => navigate(-1);

  const goToProfile = () => {
    navigate("app/user-profile");
  };

  return (
    <div className={styles.container}>
      <IconButton onClick={navigateBack}>
        <ChevronLeft color="white" />
      </IconButton>
      {userToken ? (
        <IconButton onClick={goToProfile}>
          <PersonRounded color="white" />
        </IconButton>
      ) : showMenuBar ? (
   
        <Link class={styles.tempNav} to='/app/login'>Inicio</Link>
      ) : null}
    </div>
  );
};

export default Header;
