import React from "react";
import PropTypes from "prop-types";
import styles from "./modal.module.scss";
import clsx from "clsx";


const Modal = ({children, isOpen, closeModal, position = "bottom"}) =>{
   const handleModalContainerClick = (e) => e.stopPropagation();
   const getPositionClass = (position) => {
    switch(position) {
       case "top":
          return styles.top;
       case "bottom":
          return styles.bottom;
       case "center":
       default:
          return styles.center;
        }
    };

    return(
        <article className={clsx(styles.modal, getPositionClass(position),{
            [styles.is_open]: isOpen
            })}
            onClick={closeModal}>
            <div
                className={clsx(styles.modal_container, {
                    [styles.full_width]: position === "bottom" || position === "top"
                })}
                onClick={handleModalContainerClick}
                >
                {children}
            </div>
        </article>
    )       
}

Modal.propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
}

export default Modal;