import React from 'react';
import styles from './publicity.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Publicity = ({imagePublicity, textPublicity, linkPublicity}) =>{
    return(
        <>
        <Link to={linkPublicity}>
            <div className={styles.publicity_cont}>
                <img className={styles.publicity_img} src={imagePublicity} alt={textPublicity} />
            </div>
        </Link>
        </>
    )
}

Publicity.propTypes = {
    imagePublicity: PropTypes.string,
    textPublicity: PropTypes.string,
    linkPublicity: PropTypes.string
}

export default Publicity;