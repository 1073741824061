import { Outlet } from 'react-router-dom';
import styles from './noAuthLayout.module.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const NoAuthLayout = ({noPadding}) => {
    return(
        <div className={clsx(styles.container, {
            [styles.padding_less]: noPadding
        })}>
            <Outlet />
        </div>
    )
}

NoAuthLayout.propTypes = {
    noPadding: PropTypes.bool
}

export default NoAuthLayout;