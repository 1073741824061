import React from "react";

const StoreRounded = ({color='black'}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="StoreRounded">
        <path
          id="Vector"
          d="M5.00007 6H19.0001C19.5501 6 20.0001 5.55 20.0001 5C20.0001 4.45 19.5501 4 19.0001 4H5.00007C4.45007 4 4.00007 4.45 4.00007 5C4.00007 5.55 4.45007 6 5.00007 6ZM20.1601 7.8C20.0701 7.34 19.6601 7 19.1801 7H4.82007C4.34007 7 3.93007 7.34 3.84007 7.8L2.84007 12.8C2.72007 13.42 3.19007 14 3.82007 14H4.00007V19C4.00007 19.55 4.45007 20 5.00007 20H13.0001C13.5501 20 14.0001 19.55 14.0001 19V14H18.0001V19C18.0001 19.55 18.4501 20 19.0001 20C19.5501 20 20.0001 19.55 20.0001 19V14H20.1801C20.8101 14 21.2801 13.42 21.1601 12.8L20.1601 7.8ZM12.0001 18H6.00007V14H12.0001V18Z"
          fill={color}
          //fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};


export default StoreRounded;