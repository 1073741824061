import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Toast from "../toast";
import ParticipantCounterDisplay from '../participantCounterDisplay/ParticipantCounterDisplay';
import Plate from "../../components/plate/plate";
import MapRounded from "../../styles/icons/MapRounded";
import KeyboardArrowRight from "../../styles/icons/KeyboardArrowRight";
//import IconButton from "../iconButton";
//import { ReactComponent as BellIcon } from "../../styles/icons/bell.svg";
import styles from "./conferenceCard.module.scss";

const ConferenceCard = ({
  startDate,
  title,
  speaker,
  limitParticipants,
  place,
  subscribedCounter,
  noteType,
  textNote,
  pointForConference,
  conferenceType,
  speakersOnConferences,
  onClickNavigate
}) => {

  const translateConferenceType = (type) => {
    switch(type) {
      case "talk":
        return "Charla"
      case "workshop":
        return "taller";
      default:
        return "Taller"
    }
  }

  const toastMessage = useMemo(() => {
    if (limitParticipants) {
      return 'Este es un evento con cupos limitados';
    }
    return textNote;
  },[textNote, limitParticipants]);

  const converTo12 = (time) => {
      let hour = time.substring(':');
      hour = parseInt(hour) - 12
      return `${hour}${time.substring(2)}`
  }
  const date = startDate.substring(
    0,
    startDate.indexOf("T")
  );
  let time = startDate.substring(
    startDate.indexOf("T")+1
  )
  time= converTo12(time.substring(0,time.indexOf(".")))
  return (
    <div className={styles.container} onClick={onClickNavigate}>
      <div className={styles.tagRow}>
        <div className={styles.tagContainer}>
        <span className={styles.tag}>{translateConferenceType(conferenceType)}</span>
        </div>
        <Plate
          text={place}
          icon={<MapRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>}
          />
      </div>
      <div className={styles.dateRow}>
        <span className={styles.date}>{date}</span>
        <span className={styles.date}>{time}</span>
        { pointForConference ? <span className={styles.points}>Puntos: <span className={styles.pointNumber}>{pointForConference}</span></span> : null }
        {/* TODAVIA no tenemos la funcionalidad de Notificacion activada
        
        <IconButton>
          <BellIcon />
        </IconButton>*/}
      </div>
      <div>
        <h5 className={styles.name}>{title}</h5>
        {speakersOnConferences.map((speakerOnConference,idx) => (
          
          <p key={`${idx}${speakerOnConference.user.name} ${speakerOnConference.user.familyName}`} className={styles.speaker}>{`${speakerOnConference.user.professionalTitle} ${speakerOnConference.user.name} ${speakerOnConference.user.familyName}`}</p>
        ))}
       
      </div>
      <div className={styles.counterContainer}>
        {limitParticipants ? <ParticipantCounterDisplay currentCounter={subscribedCounter} totalCounter={limitParticipants} /> : <div></div> }
        <KeyboardArrowRight color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>
      </div>
      {toastMessage ? <Toast type={noteType || 'warning'} message={toastMessage} /> : null }
    </div>
  );
};

ConferenceCard.propTypes = {
    startDate: PropTypes.string,
    title: PropTypes.string,
    speaker: PropTypes.string,
    limitParticipants: PropTypes.number,
    currentCounter: PropTypes.number,
    noteType: PropTypes.oneOf(['info', 'warning']),
    textNote: PropTypes.string,
    pointForConference: PropTypes.string,
    conferenceType: PropTypes.string,
    speakersOnConferences: PropTypes.array,
    subscribedCounter: PropTypes.number,
    onClickNavigate: PropTypes.func
}

export default ConferenceCard;
