import React, { useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { api } from "../../config/v1Api";
import { useAuthContext } from "../../context/authContext";
import Spinner from "../../components/spinner/spinner";
import Button from "../../components/button";

import styles from "./surveyList.module.scss";
const SurveyList = () => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const session = useAuthContext();

  const handleTakeSurveyCta = (masterSurveyId) => {
    const redirecPath = searchParams.get("redirect_url");
    
    navigate(
      `/app/surveys/${eventId}/quiz/form/${masterSurveyId}?redirect_url=${redirecPath}`
    );
  };
  useEffect(() => {
    (async () => {
      try {
        const payload = await api.get(`/survey/master/${eventId}`, {
          Authorization: `Bearer ${session.user}`,
        });
        setSurveys(payload.data);
        setIsLoading(false);
      } catch (e) {
        console.log("SURVEY ERROR***", e);
      }
    })();
  }, [eventId]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <h3 className={styles.headerTitle}>Encuestas</h3>
      <p className={styles.total}>
        Total de encuestas: <span>{surveys.length}</span>
      </p>
      {surveys.map((survey) => {
        console.log("SURVEY***",survey)
        return (
        <div key={survey.id} className={styles.containerSurveyCard}>
          <div>
            <h6 className={styles.title}>{survey.name}</h6>
            <p className={styles.description}>{survey.description}</p>
          </div>
          <Button onClick={() => handleTakeSurveyCta(survey.id)}>
            Iniciar Encuesta
          </Button>
        </div>
      )})}
    </div>
  );
};

export default SurveyList;
