import { useState, useEffect } from "react";
import { api } from "../../config/v1Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalSorage";
import EventCard from "../../components/eventCard/eventCard";
import Publicity from "../../components/publicity/publicity";

import styles from "./dashBoardEvent.module.scss";
const DashBoardEvent = () => {
  const [communities, setCommunities] = useState([]);
  const [events, setEvents] = useState([]);
  const [addPublicity, setAddPublicity] = useState('');

  const [sessionToken] = useLocalStorage("user");
  const location = useLocation();
  const navigate = useNavigate();
  const { communityId } = useParams();
  
  const isVisitor = location.pathname.includes("public")
  useEffect(() => {
    (async () => {
      try {
        let eventPayload = [];
        if (isVisitor) {
          eventPayload = await api.get("/events/public");
        } else {
          /* 
          Como se esta enviando el header debe ser cambiando en el futuro cercano.
          */
           const payload = await api.get('/communities/user', { 'Authorization': `Bearer ${sessionToken}` });

           const communitiesPayload = payload.data.map(c => c.community);
           setCommunities(communitiesPayload);
         
           if (communitiesPayload.length === 1) {
            eventPayload = await api.get(`/events/community/${communitiesPayload[0].id}`, { 'Authorization': `Bearer ${sessionToken}` });
            navigate(`/app/my-events/${communitiesPayload[0].id}`, { replace: true });
          }
        }
      
        setEvents(eventPayload.data);

        
            const ad = await api.get(`ad/random/6de5a41a-2dc3-41d3-b27c-67659935c8d4`);
            setAddPublicity(ad.data?.imageUrl || '');
          
      } catch (e) {
        console.log("Error Communities---", e);
      }
    })();
  }, [sessionToken]);

  const handleNavigateToEvent = (eventId) => {
    //Add log for marketing or analis data
    if (isVisitor) {
      navigate(`/app/public/events/${eventId}`);
    } else {
      navigate(`/app/${communityId}/events/${eventId}`);
    }
  };

  return (
    <div>
      <h2 className={styles.title}>Mis eventos</h2>
      <h5 className={styles.sub_title}>{communities.length === 1 ?  communities[0].shortName : 'Selector de Comunidades'}</h5>
      {events.map((event) => (
        <EventCard
          key={event.id}
          {...event}
          onClick={() => handleNavigateToEvent(event.id)}
        />
      ))}
      <div className={styles.pushDown}></div>
      <Publicity
            imagePublicity={addPublicity}
            textPublicity="Publicidad banner"
            linkPublicity="/linkpublicity"
          />
    </div>
  );
};

export default DashBoardEvent;
