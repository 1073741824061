import React from "react";

const KeyboardArrowRight = ({color="black"}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="KeyboardArrowRightRounded">
        <path
          id="Vector"
          d="M9.29006 15.88L13.1701 12L9.29006 8.12C8.90006 7.73 8.90006 7.1 9.29006 6.71C9.68006 6.32 10.3101 6.32 10.7001 6.71L15.2901 11.3C15.6801 11.69 15.6801 12.32 15.2901 12.71L10.7001 17.3C10.3101 17.69 9.68006 17.69 9.29006 17.3C8.91006 16.91 8.90006 16.27 9.29006 15.88Z"
          fill={color}
          //fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};


export default KeyboardArrowRight;
