import React from "react";

const FavoriteRounded = ({ color = "black" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="FavoriteRounded">
        <path
          id="Vector"
          d="M13.35 20.13C12.59 20.82 11.42 20.82 10.66 20.12L10.55 20.02C5.30003 15.27 1.87003 12.16 2.00003 8.28C2.06003 6.58 2.93003 4.95 4.34003 3.99C6.98003 2.19 10.24 3.03 12 5.09C13.76 3.03 17.02 2.18 19.66 3.99C21.07 4.95 21.94 6.58 22 8.28C22.14 12.16 18.7 15.27 13.45 20.04L13.35 20.13Z"
          fill={color}
          //fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};

export default FavoriteRounded;