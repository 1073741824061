import React from "react";
import PropTypes from "prop-types";
import styles from "./eventCard.module.scss";

const EventCard = ({ title, startTime, imageUrl, onClick }) => {
  return (
    <div onClick={onClick} className={styles.container}>
      <div className={styles.contentContainer}>
        <p className={styles.startDate}>{startTime.substring(
            0,
            startTime.indexOf("T")
          )}</p>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}></p>
      </div>
      <img className={styles.eventImage} src={imageUrl} alt="imagen evento" />
    </div>
  );
};

EventCard.propTypes = {
  title: PropTypes.string,
  startTime: PropTypes.string,
  onClick: PropTypes.func
};

export default EventCard;
