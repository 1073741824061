import Header from '../../components/header';
import Footer from '../../components/footer';
import { Outlet } from 'react-router-dom';
import styles from './layoutGuest.module.scss';

const LayoutGuest = () => {
    return(
        <div className={styles.app_container}>
            <Header showMenuBar={true}/>
            <div className={styles.container}>
                <Outlet />
            </div>
            <div id="portalForPublicity" className={styles.publicity_container}></div>
            <Footer/>
        </div>
    )
}

export default LayoutGuest;