import React from 'react';
import { useAddToHomescreenPrompt } from '../../hooks/useTriggerPwaPromt';
import Buton from '../../components/button';
import { Link } from 'react-router-dom';
import ueventIcon from '../../styles/images/ueventIcon.png';

import styles from './homepage.module.scss';
const Homepage = () => {
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = React.useState(false);
    React.useEffect(
        () => {
          if (prompt) {
            setVisibleState(true);
          }
        },
        [prompt]
      );
    
    return (
        <div className={styles.container}>
            <h1 className={styles.welcomTitle}>Bienvenido a</h1>
            <img src={ueventIcon} className={styles.companyIcon} alt="Logo Uevent"/>
            <ul className={styles.phrase}>
              <li>Colocamos tu perfil como charlista a la vista de todos.</li> 
               <li>Te conecta hoy y en el futuro con tus charlas.</li>
               <li>Ver los perfiles de tu charlistas favoritos.</li> 
            </ul>
            
            {
                isVisible ?
                <Buton id="pwa_install" onClick={promptToInstall}>Instalar</Buton>
                : null }

<Link to={'/app/login'} className={styles.goLogin}>Ingresar</Link>

<p className={styles.installedMsg}>Viva una mejor experiencia utilizado la app.</p>
        </div>
    )
}

export default Homepage;
