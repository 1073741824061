import React, { useState } from "react";
import styles from "./materialList.module.scss";
import Button from "../../components/button";
import { Link } from "react-router-dom";
import ArrowBackRounded from '../../styles/icons/ArrowBackRounded.jsx';
import ResourceItem from "../../components/resourceItem/ResourceItem.jsx";

const MaterialsList = () =>{
    
    const material = {
        supportMaterial: [
          {
            title: 'archivo-largeadipiscing elit.pdf',
            type: 'pdf',
          },
          {
            title: 'archivo-largeadipiscing elit.zip',
            type: 'zip',
          },
          {
            title: 'archivo-largeadipiscing elit.link',
            type: 'link',
          },
          {
            title: 'archivo-largeadipiscing elit.pdf',
            type: 'pdf',
          },
          {
            title: 'archivo-largeadipiscing elit.zip',
            type: 'zip',
          },
          {
            title: 'archivo-largeadipiscing elit.link',
            type: 'link',
          },
          {
            title: 'archivo-largeadipiscing elit.pdf',
            type: 'pdf',
          },
          {
            title: 'archivo-largeadipiscing elit.zip',
            type: 'zip',
          },
          {
            title: 'archivo-largeadipiscing elit.link',
            type: 'link',
          }
        ]
    };

    const [materialDetail, setMaterialDetail] = useState(material);

    return(
        <div>
            <h1 className={styles.title}>Material de referencia</h1>
            {
                materialDetail.supportMaterial.map((material,idx)=><ResourceItem key={idx} title={material.title} type={material.type} /> )
            }
            <Link to="/events/1/talk/1">
                <Button 
                    variant="primary"
                    icon={<ArrowBackRounded color="#ffffff"/>}
                    iconPosition="left"
                    fullWidth>
                    Volver a la charla
                </Button>
            </Link>
        </div>
    )
}

export default MaterialsList;