import React, { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuthContext } from "../../context/authContext";
import { api } from "../../config/v1Api";
import Spinner from "../../components/spinner/spinner";
import Button from "../../components/button";
import Toast from "../../components/toast";

import styles from "./surveyQuiz.module.scss";
const Quiz = () => {
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState("");
  const [result, setResult] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const { masterSurveyId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const session = useAuthContext();

  const handelResultInputs = (type, answer, questionId) => (e) => {
    let inputValue;
    if (("checkbox" === type) | ("radio" === type)) {
      const { checked } = e.target;
      inputValue = checked;
    } else {
      const { value } = e.target;
      inputValue = value;
    }
    
    setResult((prev) => {
      return {
        ...prev,
        [`${questionId}-${type === 'input' ? 0 : answer}`]: {
          questionId,
          answerType: type,
          answer: inputValue.toString(),
          answerDescription: answer || 'pregunta abierta'
        },
      };
    });
  };

  const structorAnswer = (answers) => {
    return answers.split("|");
  };

  const generateType = (type, answer, questionId) => {
    switch (type) {
      case "checkbox":
        return (
          <>
            <input
              type="checkbox"
              onChange={(e) => handelResultInputs(type, answer, questionId)(e)}
            />{" "}
            <span className={styles.answerText}>{answer}</span>
          </>
        );
      case "radio":
        return (
          <>
            <input
              type="radio"
              onChange={(e) => handelResultInputs(type, answer, questionId)(e)}
              name={questionId}
            />{" "}
            <span className={styles.answerText}>{answer}</span>
          </>
        );
      case "input":
        return (
          <>
            <input
              type="text"
              onChange={(e) => handelResultInputs(type, answer, questionId)(e)}
              className={styles.inputAnswer}
            />
          </>
        );
      default:
        return (
          <>
            <input
              type="text"
              onChange={(e) => handelResultInputs(type, answer, questionId)(e)}
            />{" "}
            <span className={styles.answerText}>{answer}</span>
          </>
        );
    }
  };

  const handleSubmitSurvey = async () => {
    if (Object.keys(result).length >= questions.length) {
      try {
        setIsSaving(true);
        await api.post(`/survey/answer/${masterSurveyId}`, result, {
          Authorization: `Bearer ${session.user}`,
        });

        const redirecPath = searchParams.get("redirect_url");
    
        navigate(
          `${redirecPath}`
        );
      } catch (e) {
        console.log("Borrar ver erorr---", e);
        setIsSaving(false);
      } finally {
        setIsSaving(false);
      }
    } else {
      setError("Favor de responder todas las preguntas.");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const questionsPayload = await api.get(
          `/survey/questions/${masterSurveyId}`,
          {
            Authorization: `Bearer ${session.user}`,
          }
        );

        setQuestions(questionsPayload.data);
        setIsLoading(false);
      } catch (e) {}
    })();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  if (questions.length < 1) {
    return <p>No existen preguntas para esta encuesta</p>;
  }
  return (
    <div>
      {isSaving ? <Spinner /> : null}
      <h4 className={styles.title}>{questions[0].masterSurvey.name}</h4>
      <p className={styles.infoTitle}>
        Total de preguntas a responder: {questions.length}
      </p>
      {questions.map((question, idx) => (
        <div key={question.id} className={styles.questionCard}>
          {
            <p className={styles.question}>
              <span className={styles.number}>{idx + 1}.</span>
              {question.question}
            </p>
          }
          {question?.answers
            ? structorAnswer(question.answers).map((answer, idx) => {
                return (
                <div key={`${question.id}-${idx}`}>
                  {generateType(question.type, answer, question.id)}
                </div>
              )})
            : generateType("input", undefined, question.id)}
        </div>
      ))}
      {error ? <Toast type="error" message={error} /> : null}
      <Button onClick={handleSubmitSurvey} fullWidth>
        Finalizar encuentas
      </Button>
    </div>
  );
};

export default Quiz;
