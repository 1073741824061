import React, { useEffect, useState } from "react";
import { api } from "../../config/v1Api";
import { useParams } from "react-router-dom";
import Toast from "../../components/toast";
import CatalogueItem from "../../components/catalogueItem/CatalogueItem";

import styles from "./catalogue.module.scss";

const Catalogue = () => {
  const [products, setProducts] = useState([]);

  const { eventId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const payload = await api.get(`/catalogue/${eventId}`);
        setProducts(payload.data.catalogueProducts);
      } catch (e) {
        console.log("SHOW ERROR**", e);
      }
    })();
  }, [eventId]);

  return (
    <>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>Catálogo</h2>
        <Toast
          type="warning"
          message="Podrás adquirir cualquiera de estos productos durante 
el evento. Contacta al staff para más información."
        />
      </div>
      {products.map((product) => (
        <CatalogueItem key={product.id} {...product} />
      ))}
    </>
  );
};

export default Catalogue;
