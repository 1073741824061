import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as Alert} from '../../styles/icons/alert.svg';
import {ReactComponent as InfoRounded} from '../../styles/icons/InfoRoundedBlue.svg';
import {ReactComponent as ErrorRounded} from '../../styles/icons/ErrorRounded.svg';
import styles from './toast.module.scss';

const Toast = ({type, message}) => {

    const getIcon = (typeIcon) => {
        switch(typeIcon) {
            case 'warning':
              return <Alert />;
            case 'info':
                return <InfoRounded/>;
            case 'error':
                return <ErrorRounded/>;
            default: return <Alert/>;
        }
    }
    
    return (<div className={`${styles.container} ${styles[type]}`}>
        <div className={styles.iconContainer}>{getIcon(type)}</div>
        <p className={styles.message}>{message}</p>
    </div>)
}

Toast.propTypes = {
    type: PropTypes.oneOf(['warning','info']),
    message: PropTypes.string,
}

export default Toast;
