import React from 'react';
import PropTypes from 'prop-types';
import styles from './participantCounterDisplay.module.scss';

const ParticipantCounterDisplay = ({
    currentCounter,
    totalCounter
}) => {
    return (
        <p className={styles.counter}>
        Cupos{" "}
        <span className={styles.participantCounter}>
          {currentCounter} de {totalCounter}
        </span>
      </p>
    )
}

ParticipantCounterDisplay.propTypes = {
    currentCounter: PropTypes.number,
    totalCounter: PropTypes.number
}

export default ParticipantCounterDisplay;
