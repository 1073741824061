import React, { useState } from "react";
import styles from "./questionInput.module.scss";
import Button from "../button";
import { api } from "../../config/v1Api";
import { useParams } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalSorage";

const QuestionInput = ({ addQuestion }) => {
  const [question, setQuestion] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { talkId } = useParams();
  const [sessionToken] = useLocalStorage("user");

  const saveQuestion = async () => {
    try {
      if (sessionToken) {
        //Aqui va logica de api con debounce
        setIsSaving(true);
        await api.post(
          "/conference/question",
          {
            conferenceId: talkId,
            question: question,
          },
          { Authorization: `Bearer ${sessionToken}` }
        );
        addQuestion();
        setIsSaving(false);
        setQuestion("");
      }
    } catch (e) {
      console.log("error like***", e);
    }
  };

  const handleQuestion = () => {
    if (question.length > 5) {
      saveQuestion();
    }
  };

  return (
    <>
      <div className={styles.form_input}>
        <label>Deja aquí tu pregunta</label>
        <input
          className={styles.input_control}
          type="text"
          placeholder="Escribe la pregunta que tengas para la charla"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <span className={styles.error_message}>* Máximo 250 caracteres</span>
      </div>
      <Button
        variant="primary"
        onClick={handleQuestion}
        fullWidth
        disabled={isSaving || !sessionToken}
      >
        Enviar pregunta
      </Button>
    </>
  );
};

export default QuestionInput;
