import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuthContext } from "../context/authContext";

export const LogOutUser = ({children}) =>{
    const location = useLocation();
    const auth = useAuthContext();

    if(auth.user) {
        const newPathname = location.pathname.replace('/public', '')
        return(
            <Navigate
                to={newPathname}
                state={{path: location.pathname}}
            />
        )
    }

    return <Outlet />
}