import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";
import { api } from "../../config/v1Api";
import ProfileCardData from "../../components/profileCardData/profileCardData";
import OptionLinkProfile from "../../components/optionLinkProfile/optionLinkProfile";
//import PersonRounded from "../../styles/icons/PersonRounded";
//import LanguageRounded from "../../styles/icons/LanguageRounded";
//import ShareRounded from "../../styles/icons/ShareRoundedIcon";
//import SettingsRounded from "../../styles/icons/SettingsRounded";
//import ContactSupportRounded from "../../styles/icons/ContactSupportRounded";
import LogoutRounded from "../../styles/icons/LogoutRounded";
import useLocalStorage from "../../hooks/useLocalSorage.js";
import ueventUicon from '../../styles/images/ueventUicon.jpg';

import styles from "./userProfile.module.scss";

const UserProfile = () => {
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const auth = useAuthContext();
  const navigate = useNavigate();
  const [sessionToken] = useLocalStorage("user");

  const handleLogout = () => {
    auth.logout();
    navigate("/app/login");
  };

  useEffect(() => {
    (async () => {
      try {
        const profile = await api.get("/user/profile", {
          Authorization: `Bearer ${sessionToken}`,
        });

        setProfile(profile.data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <>
      <div className={styles.header_speaker}>
        <div className={styles.content_header}>
          <img
            className={styles.photo_speaker}
            src={ueventUicon}
            alt="Speaker name"
          />
          <div className={styles.content_detail_speaker}>
            <h2
              className={styles.name_speaker}
            >{`${profile.name} ${profile.familyName}`}</h2>
            <p className={styles.mail_speaker}>{profile.email}</p>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div>
          {/*className={styles.content_card_flex}*/}
          <ProfileCardData
            numberData={profile._count.events}
            typeData="Eventos asistidos"
          />
          {/*<ProfileCardData numberData={5} typeData='Charlas realizadas'/>*/}
        </div>
        <div>
          {profile.points.map((point) => (
            <ProfileCardData
              key={point.name}
              numberData={point.point}
              typeData={point.name}
            />
          ))}
        </div>
        <div className={styles.content_options}>
          {/* <OptionLinkProfile IconOption={<PersonRounded color='#062973'/>} textOption='Datos personales' linkOption='/link' />
                <OptionLinkProfile IconOption={<LanguageRounded color='#062973'/>} textOption='Lenguaje' linkOption='/link' />
                <OptionLinkProfile IconOption={<ShareRounded color='#062973'/>} textOption='Compartir' linkOption='/link' />
                <OptionLinkProfile IconOption={<SettingsRounded color='#062973'/>} textOption='Configuración' linkOption='/link' />
                <OptionLinkProfile IconOption={<ContactSupportRounded color='#062973'/>} textOption='Soporte técnico' linkOption='/link' />
                */}
          <OptionLinkProfile
            IconOption={<LogoutRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"} />}
            textOption="Cerrar sesión"
            handleClick={handleLogout}
            noBorderBottom
          />
        </div>
      </div>
    </>
  );
};

export default UserProfile;
