import React from 'react';
import PropTypes from 'prop-types';
import styles from './speakerCardTalk.module.scss';
import EventIcon from "../../styles/icons/EventIcon";

const SpeakerCardTalk = ({titleTalk, descriptionTalk, dateTalk, nameEvent}) => {

    return (
        <div className={styles.cont_card}>
            <h5 className={styles.title_talk}>{titleTalk}</h5>
            <p className={styles.description_talk}>{descriptionTalk}</p>
            <p className={styles.date_talk}>{dateTalk}</p>
            <p className={styles.event_talk}><EventIcon color='#062973'/> {nameEvent}</p>
        </div>
    )
}

SpeakerCardTalk.propTypes = {
    titleTalk: PropTypes.string,
    descriptionTalk: PropTypes.string,
    dateTalk: PropTypes.string,
    nameEvent: PropTypes.string
}

export default SpeakerCardTalk;