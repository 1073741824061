import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./plate.module.scss";

const Plate = ({ text, icon, label, iconPosition = "left", className }) => {
  return (
    <div className={styles.datePlateSeparators}>
      { label !== '' ? <label className={styles.label}>{label}</label> : null}
      <div className={styles.iconLabel}>
        {iconPosition === "left" ? icon : null}
        <span
          className={clsx(styles.text, {
            [styles.textRight]: iconPosition === "left",
            [styles.textLeft]: iconPosition === "right",
            [className]: className
          })}
        >
          {text}
        </span>
        {iconPosition === "right" ? icon : null}
      </div>
    </div>
  );
};

Plate.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
};

export default Plate;
