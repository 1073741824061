import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { api } from "../../config/v1Api.js";
import talkImage from "../../styles/images/RectangleTalk.png";
import FavoriteRounded from "../../styles/icons/FavoriteRounded";
import FavoriteBorderRounded from "../../styles/icons/FavoriteBorderRounded.jsx";
import Plate from "../../components/plate/plate";
import ParticipantCounterDisplay from "../../components/participantCounterDisplay/ParticipantCounterDisplay";
import Button from "../../components/button";
import SpeakerDisplay from "../../components/speakerDisplay/SpeakerDisplay";
import CalendarMonthRounded from "../../styles/icons/calendarMonthRounded";
import MapRounded from "../../styles/icons/MapRounded";
import ThumbUpIcon from "../../styles/icons/ThumbUp";
import AddCircleIcon from "../../styles/icons/AddCircleIcon";
import RefreshRounded from "../../styles/icons/RefreshRounded";
import Toast from "../../components/toast";
import ResourceItem from "../../components/resourceItem/ResourceItem";
import QuestionCard from "../../components/questionCard/questionCard";
import QuestionInput from "../../components/questionInput/questionInput";
import Modal from "../../components/modal/index";
import IconButton from "../../components/iconButton/index";
import ModalRedirect from "../../components/modalRedirect/modalRedirect";
import useLocalStorage from "../../hooks/useLocalSorage.js";
import ErrorRoundedIcon from "../../styles/icons/ErrorRounded.jsx";

import styles from "./talkDetail.module.scss";
import DeleteRounded from "../../styles/icons/DeleteRounded.jsx";
import Spinner from "../../components/spinner/spinner.jsx";

const TalkDetail = () => {
  const [talkDetail, setTalkDetail] = useState({});
  const [showQuestion, setShowQuestion] = useState({ id: "", question: "", vote: false, questionId: '' });
  const [TALK_STATE, SET_TALK_STATE] = useState("init");
  const [questionsList, setQuestionsList] = useState([{ id: 0, question: "" }]);
  const [isQuestionLoading, setIsQuestionLoading] = useState(false);

  const modalRef = useRef();
  const [sessionToken] = useLocalStorage("user");
  const navigate = useNavigate();
  const location = useLocation();
  const { talkId, questionId } = useParams();
  const isVisitor = location.pathname.includes("public");

  const openModalQuestion = (id, question, vote, questionId) => {
    setShowQuestion({ id, question, vote, questionId });
  };

  const closeModalQuestion = () => {
    setShowQuestion({ id: "", question: "", vote: false, questionId: "" });
  };

  const fetchQuestion = () => {
    try {
        getQuestionList();
    
      
    } catch (e) {
      console.log("ERORRO***", e);
    }
  };

  const getQuestionList = useCallback(
    debounce(async () => {
      try {
        setIsQuestionLoading(true);
        if (sessionToken) {
          const questionsPayload = await api.get(
            `/conference/questions/${talkId}?take=5`,
          { Authorization: `Bearer ${sessionToken}` }
          );
          
          const q = questionsPayload.data.map(question => (
            {...question,
              vote: question.votes.length > 0 ? question.votes[0].vote : false
            }
          ))
          setQuestionsList(q);
         } else {
          const questionGuest = await api.get(
            `/conference/questions/guest/${talkId}?take=5`
          );
          
          const qGuest = questionGuest.data.map(question => (
            {...question,
              vote: false
            }
          ))
          setQuestionsList(qGuest);
         }
        
        setIsQuestionLoading(false);
      
      } catch (e) {
        setIsQuestionLoading(false);
      }
    }, 900),
    []
  );

  const addQuestion = () => {
    getQuestionList();
  };

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.openModal();
    }
  };

  const handleSubscribe = () => {
    console.log("Estoy subscrito");
  };

  const handleFunctionModal = () => {
    if (sessionToken) {
      handleSubscribe();
    } else {
      handleOpenModal();
    }
  };

  const handleNavigateSpeakerProfileCTA = (userId) => {
    if (isVisitor) {
      navigate(`/app/public/speaker/${userId}`);
    } else {
      navigate(`/app/speaker/${userId}`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postLike = useCallback(
    debounce(async (voteOption) => {
      try {
        await api.post(
          "/conference/vote",
          {
            conferenceId: talkId,
            vote: voteOption,
          },
          { Authorization: `Bearer ${sessionToken}` }
        );
      } catch(e) {

      }
     
    }, 900),
    []
  );

  const giveLikeToQuestion = (voteOption, questionId) => {
    if (sessionToken) {
      postQuestionLike(voteOption, questionId);
      closeModalQuestion()
    } else {handleOpenModal()}
  }
  const postQuestionLike = useCallback(
    debounce(async (voteOption, questionId) => {
      try {
        await api.post(
          "/conference/question/vote",
          {
            questionId,
            vote: voteOption,
          },
          { Authorization: `Bearer ${sessionToken}` }
        );
        getQuestionList();
      } catch(e) {
          console.log('EU**', e)
      }
   
    }, 900),
    []
  );

  const handleClickLikeButton = async () => {
    if (sessionToken) {
      const voteOption = !talkDetail.vote;
      setTalkDetail((prev) => {
        return {
          ...prev,
          vote: !prev.vote,
          voteTalkCount: !prev.vote
            ? prev.voteTalkCount + 1
            : prev.voteTalkCount - 1,
        };
      });

      try {
        //Aqui va logica de api con debounce
        postLike(voteOption);
      } catch (e) {
        console.log("error like***", e);
      }
    } else {
      handleOpenModal();
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const payload = await api.get(`/conference/${talkId}`);
        let currentVote = false;
        if (payload.data.votes.length) {
          currentVote = payload.data.votes[0].vote;
        }
        const voteTalkCount = payload.data._count.votes;
        setTalkDetail({ ...payload.data, vote: currentVote, voteTalkCount });
       
        const q = payload.data.conferenceQuestoin.map(question => (
          {...question,
            vote: question.votes.length > 0 ? question.votes[0].vote : false
          }
        ));
        setQuestionsList(q);
        SET_TALK_STATE("feched");
      } catch (e) {
        console.log("SOMETHING HAPPNE***", e);
      }
    })();
  }, [talkId]);

  // este es el use effect para hacer fetch de todas las preguntas
  /*
  useEffect(() => {
    const fetchAllQuestions = async () => {
      try {
        const response = await fetch(`/conference/questions/${talkId}`);
        if (!response.data.length) {
          throw new Error('Error fetching questions');
        }
        const payload = await response.json();
        const modifedQuestionList = payload.data.map((question, index) =>({
          ...question,
          id: index +1
        })).reverse();
        setQuestions(modifedQuestionList);
      } catch (e) {
        console.log("SOMETHING HAPPNE***", e);
      }
    };

    fetchAllQuestions();
  }, [talkId]);
*/

  if (TALK_STATE === "init") {
    return <Spinner />;
  }

  return (
    <>
      <ModalRedirect ref={modalRef} modalText="Favor de iniciar session" position="top" />
      <h3 className={styles.title}>{talkDetail.title}</h3>
      <img className={styles.talkImage} alt="talk img" src={talkImage} />
      <div className={styles.plateContainer}>
        <Plate
          text={talkDetail.startDate.substring(
            0,
            talkDetail.startDate.indexOf("T")
          )}
          icon={<CalendarMonthRounded color="#FCA311" />}
        />
        <Plate text={talkDetail.place} icon={<MapRounded color="#FCA311" />} />
      </div>
      <p className={styles.talkDescription}>{talkDetail.description}</p>

      <Plate
        iconPosition="left"
        text={talkDetail?.voteTalkCount || "0"}
        icon={
          <IconButton onClick={handleClickLikeButton}>
            <ThumbUpIcon color={
              talkDetail.vote
              ? (window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FCA311' : '#062973') 
              : '#B1B1B1'
            } />
          </IconButton>
        }
        className={styles.likeText}
      />

      {talkDetail.limitParticipants ? (
        <div className={styles.subscriptionsContainer}>
          <ParticipantCounterDisplay
            currentCounter={talkDetail.currentCounter}
            totalCounter={talkDetail.totalCounter}
          />
          <Button
            variant="support"
            iconPosition="right"
            icon={<AddCircleIcon color="#062973" />}
            onClick={handleFunctionModal}
          >
            Suscribirme
          </Button>
        </div>
      ) : null}

      <h5 className={styles.subTitle}>Expositores de esta charla</h5>
      {talkDetail?.speakersOnConferences?.map((speaker, idx) => {
        return (
          <SpeakerDisplay
            key={`${speaker.user.name}-${idx}`}
            fullName={`${speaker.user.professionalTitle} ${speaker.user.name} ${speaker.user.familyName}`}
            imageUrl={speaker.user.imageUrl}
            onClickNavigate={() =>
              handleNavigateSpeakerProfileCTA(speaker.user.id)
            }
          />
        );
      })}

      <div className={styles.referenceTitle}>
        <h5 className={styles.subTitle}>Material de referencia</h5>
        {talkDetail.conferenceReferenceMaterials.length > 5 ? (
          <Link to="/events/1/talk/1/list-materials">
            <Button variant="link">Ver más</Button>
          </Link>
        ) : null}
      </div>
      {talkDetail?.conferenceReferenceMaterials.length > 0 ? (
        talkDetail?.conferenceReferenceMaterials?.map((material) => (
          <ResourceItem
            key={material.idx}
            title={material.title}
            type={material.materialExtension}
          />
        ))
      ) : (
        <Toast
          type="info"
          message="En este momento no tenemos los materiales de referencia disponible. Haremos lo posible por obtener, para futuras consultas."
        />
      )}

      <div className={styles.referenceTitle}>
        <h5 className={styles.subTitle}>
          Preguntas
          {isQuestionLoading ? (
            <ErrorRoundedIcon />
          ) : (
            <IconButton
              onClick={fetchQuestion}
              className={styles.button_refresh}
            >
              <RefreshRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"} />
            </IconButton>
          )}
        </h5>
        {talkDetail?.conferenceQuestoin.length > 4 ? (
          <Link to={`${location.pathname}/list-questions`}>
            <Button variant="link">Ver más</Button>
          </Link>
        ) : null}
      </div>
      {talkDetail?.conferenceQuestoin.length > 0 ? (
        questionsList.map((element, idx) => (
          <QuestionCard
            key={element.id}
            indexQuestion={questionsList.length - idx}
            question={element.question}
            onClick={openModalQuestion}
            likes={`${element._count.votes} Me gusta`}
            vote={element.vote}
            questionId={element.id}
          />
        ))
      ) : (
        <Toast type="info" message="Se el primero en hacer una pregunta!" />
      )}

      <Modal isOpen={showQuestion.id !== ""} closeModal={closeModalQuestion}>
        <div className={styles.content_modal}>
          <h4 className={styles.title_modal}>Pregunta #{showQuestion.id}</h4>
          {<p>{showQuestion.question}</p>}
          <div className={styles.actions_modal}>
            <IconButton variant="secondary" className={styles.button_icon}>
              <DeleteRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FCA311' : "#062973"} />
            </IconButton>
            <IconButton onClick={() => giveLikeToQuestion(!showQuestion.vote, showQuestion.questionId)} variant="secondary" className={styles.button_icon}>
              { showQuestion.vote && sessionToken ? <FavoriteRounded color="#062973" /> : 
              <FavoriteBorderRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FCA311' : "#062973"}/>}
            </IconButton>
            <Button variant="primary" onClick={closeModalQuestion}>
              Volver
            </Button>
          </div>
        </div>
      </Modal>

      <QuestionInput addQuestion={addQuestion} />
    </>
  );
};

export default TalkDetail;
