import React from "react";
import PropTypes from 'prop-types';
import styles from './questionCard.module.scss';
import clsx from "clsx";
import ThumbUpIcon from "../../styles/icons/ThumbUp";
import DeleteRounded from "../../styles/icons/DeleteRounded";
import Button from "../button";

const QuestionCard = ({ indexQuestion, question, onClick, likes, vote, questionId }) => {
    const openModal = () =>{
      onClick(
        indexQuestion,
        question,
        vote,
      questionId)
    }
  
    return (
      <div 
        className={styles.cont_card}
        onClick={openModal}>
        <span className={styles.index_question}>{indexQuestion}. </span>
        <p className={clsx(  
                        styles.name_elipsis,
                        styles.question_paragraph
                      )}> {question}</p>
        <div className={styles.likes}><ThumbUpIcon color="#062973" /> {likes}</div>
      </div>
    );
  };

  QuestionCard.propTypes = {
    indexQuestion: PropTypes.number,
    question: PropTypes.string,
    likes: PropTypes.string,
    onClick: PropTypes.func,
    vote: PropTypes.bool,
    questionId: PropTypes.string
}
  
export default QuestionCard;