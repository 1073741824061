import Header from '../../components/header';
import Footer from '../../components/footer';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './layoutAuth.module.scss';
import clsx from 'clsx';

const LayoutAuth = ({noPadding}) => {
    return(
        <div className={styles.app_container}>
            <Header/>
            <div className={clsx(styles.container, {
                [styles.padding_less]: noPadding
            })}>
                <Outlet />
            </div>
            <div id="portalForPublicity" className={styles.publicity_container}></div>
            <Footer/>
        </div>
    )
}

LayoutAuth.propTypes = {
    noPadding: PropTypes.bool
}

export default LayoutAuth;