import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRouter from "./privateRouter";
import Event from "../views/event";
import TalkDetail from "../views/talkDetail";
import NotFoundPage from "../views/notFoundPage";
import QuestionsList from "../views/questionsList";
import MaterialsList from "../views/materialsList";
import LayoutAuth from "../layout/authLayout/layoutAuth";
import NoAuthLayout from "../layout/noAuthLayout/noAuthLayout";
import SpeakerProfile from "../views/speakerProfile";
import Login from "../views/auth/login/login";
import DashBoardEvent from "../views/dashBoardEvent/dashBoardEvent";
import UserProfile from "../views/userProfile";
import Catalogue from "../views/catalogue";
import { LoggedinInUsers } from "../utilities/loggedInUsers";
import { LogOutUser } from "../utilities/LogOutUser";
import Homepage from "../views/homepage/homepage";
import LayoutGuest from "../layout/guestLayout/layoutGuest";
import SurveyList from "../views/survey/surveyList";
import SurveyQuiz from "../views/survey/surveyQuiz";
import Register from "../views/auth/register/register";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />

        <Route element={<PrivateRouter />}>
          <Route element={<LayoutAuth />}>
            <Route
              path="app/my-events/:communityId?"
              element={<DashBoardEvent />}
            />
            <Route path="app/:communityId/events">
              <Route path=":eventId" element={<Event />} />
              <Route
                path=":eventId/catalogue/:catalogueId?"
                element={<Catalogue />}
              />
              <Route path=":eventId/talk/:talkId" element={<TalkDetail />} />
              <Route
                path=":eventId/talk/:talkId/list-questions"
                element={<QuestionsList />}
              />
              <Route
                path=":eventId/talk/:talkId/list-materials"
                element={<MaterialsList />}
              />
            </Route>
            <Route
              path="app/surveys/:evemtId/quiz/form/:masterSurveyId"
              element={<SurveyQuiz />}
            />
            <Route
              path="app/surveys/:eventId/:talkId?"
              element={<SurveyList />}
            ></Route>
          </Route>
          <Route element={<LayoutAuth noPadding />}>
            <Route path="app/speaker/:speakerId" element={<SpeakerProfile />} />
            <Route path="app/user-profile" element={<UserProfile />} />
          </Route>
        </Route>

        <Route path="app" element={<NoAuthLayout />}>
          <Route
            path="login"
            element={
              <LoggedinInUsers>
                <Login />
              </LoggedinInUsers>
            }
          />
          <Route
            path="registrar"
            element={
              <LoggedinInUsers>
                <Register />
              </LoggedinInUsers>
            }
          />
          <Route path="public">
            <Route element={<LogOutUser />}>
              <Route element={<LayoutGuest />}>
                <Route path="events">
                  <Route index element={<DashBoardEvent />} />
                  <Route path=":eventId" element={<Event />} />
                  <Route
                    path=":eventId/talk/:talkId"
                    element={<TalkDetail />}
                  />
                  <Route
                    path=":eventId/talk/:talkId/list-questions"
                    element={<QuestionsList />}
                  />
                  <Route
                    path=":eventId/catalogue/:catalogueId?"
                    element={<Catalogue />}
                  />
                </Route>
              </Route>
            </Route>
            <Route element={<LayoutAuth noPadding />}>
              <Route path="speaker/:speakerId" element={<SpeakerProfile />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
