import axios from 'axios';

const axiosClient = axios.create();

axiosClient.defaults.baseURL =
  `${process.env.REACT_APP_API_BASE_URL || 'https://api.sociedadplus.com'}/api/v1`; // This is temporal

axiosClient.defaults.headers.common['Content-Type'] = 'application/json';
axiosClient.defaults.headers.common['Accept'] = 'application/json';

// All request will wait 4 seconds before timeout
axiosClient.defaults.timeout = 4000;

// axiosClient.defaults.withCredentials = true;

export { axiosClient };