import React from 'react';
import PropTypes from 'prop-types';
import styles from './socialMediaCard.module.scss';
import LinkedinIcon from '../../styles/icons/LinkedinIcon';
import Xicon from '../../styles/icons/XiconMedia';
import WebIcon from '../../styles/icons/WebIcon';
import InstagramIcon from '../../styles/icons/InstagramIcon';

const SocialMediaCard = ({title, type}) => {
    let socialType = <WebIcon color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>;
    switch(type) {
        case 'linkedin':
            socialType = <LinkedinIcon color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>;
            break;
        case 'web':
            socialType = <WebIcon color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>;
            break;
        case 'instagram':
            socialType = <InstagramIcon color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>;
            break;
        case 'x':
            socialType = <Xicon color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>;
            break;
        default:
            socialType = <WebIcon color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>;
            
    }
    return (
        <div className={styles.cont_card}>
            <div>{socialType}</div>
            <span className={styles.name_social}>
                {title}
            </span>
        </div>
    )
}

SocialMediaCard.propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['linkedin','web','instagram', 'x'])
}

export default SocialMediaCard;