import { axiosClient } from './axiosClient';

axiosClient.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    /// Here we add the code to log all catch endpoints
    //  code
    ///
    return Promise.reject(error);
  }
);

const get = (url, headers) => {
  return axiosClient
    .get(url, {headers})
    .then((res) => res)
    .catch((e) => {
      throw e;
    });
};

const post = (url, payload, headers) => {
  return axiosClient
    .post(url, payload, {headers})
    .then((res) => res)
    .catch((e) => {
      throw new Error(e.response.data.code, e.response.data.message); // Crear clase para manejar Error de forma mas estandar
    });
};

const patch = (url, payload, headers) => {
  return axiosClient
    .patch(url, payload, {headers})
    .then((res) => res)
    .catch((e) => {
      throw e;
    });
};

const deleteRequest = (url) => {
  return axiosClient
    .delete(url)
    .then((res) => res)
    .catch((e) => {
      throw e;
    });
};

export const api = {
  get,
  post,
  patch,
  delete: deleteRequest,
};
