import React from "react";

const FavoriteBorderRounded = ({ color = "black" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="FavoriteBorderRounded">
        <path
          id="Vector"
          d="M19.66 3.99C17.02 2.19 13.76 3.03 12 5.09C10.24 3.03 6.97997 2.18 4.33997 3.99C2.93997 4.95 2.05997 6.57 1.99997 8.28C1.85997 12.16 5.29997 15.27 10.55 20.04L10.65 20.13C11.41 20.82 12.58 20.82 13.34 20.12L13.45 20.02C18.7 15.26 22.13 12.15 22 8.27C21.94 6.57 21.06 4.95 19.66 3.99ZM12.1 18.55L12 18.65L11.9 18.55C7.13997 14.24 3.99997 11.39 3.99997 8.5C3.99997 6.5 5.49997 5 7.49997 5C9.03997 5 10.54 5.99 11.07 7.36H12.94C13.46 5.99 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55Z"
          fill={color}
          //fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};

export default FavoriteBorderRounded;