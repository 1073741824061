import React from 'react';
import MainRouter from './router/mainRouter'
import { AuthProvider } from './context/authContext';
import { Provider, ErrorBoundary, LEVEL_WARN } from '@rollbar/react';
import Spinner from './components/spinner/spinner';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ENV,
};

const ErrorDisplay = ({ error, resetError }) => (
  <div>
    Se presento un error, favor reintentar
  </div>
);

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary level={LEVEL_WARN} fallbackUI={ErrorDisplay}>
        <AuthProvider>
          {/* <Spinner /> */}
          <MainRouter />
        </AuthProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
