import { createContext, useContext } from "react";
import useLocalStorage from "../hooks/useLocalSorage";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) =>{
    const [user, setUser] = useLocalStorage("user", "");

    const login = user =>{
        setUser(user);
    }

    const logout = () => {
        setUser(null);
    }

    return <AuthContext.Provider value={{user, login, logout}}>{children}</AuthContext.Provider>
}

export const useAuthContext = () =>{
    const context = useContext(AuthContext)
    if (context === undefined) {
      throw new Error('Auth Context must be used within a AuthProvider')
    }
      return context
  }