import React from "react";

const FollowTheSignsRounded = ({color='black'}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="FollowTheSignsRounded">
        <path
          id="Vector"
          d="M9.5 5.5C10.6 5.5 11.5 4.6 11.5 3.5C11.5 2.4 10.6 1.5 9.5 1.5C8.4 1.5 7.5 2.4 7.5 3.5C7.5 4.6 8.4 5.5 9.5 5.5ZM5.75 8.9L3.23 21.81C3.11 22.43 3.58 23 4.21 23H4.3C4.77 23 5.18 22.67 5.28 22.21L6.85 15L9 17V22C9 22.55 9.45 23 10 23C10.55 23 11 22.55 11 22V15.86C11 15.59 10.89 15.34 10.71 15.15L8.95 13.4L9.55 10.4C10.62 11.72 12.13 12.63 13.86 12.91C14.46 13.01 15 12.52 15 11.91C15 11.42 14.64 11.01 14.16 10.93C12.67 10.68 11.41 9.78 10.65 8.55L9.7 6.95C9.35 6.35 8.7 6 8 6C7.75 6 7.5 6.05 7.25 6.15L2.62 8.05C2.25 8.2 2 8.57 2 8.97V12C2 12.55 2.45 13 3 13C3.55 13 4 12.55 4 12V9.65L5.75 8.9ZM21 2H14C13.45 2 13 2.45 13 3V8C13 8.55 13.45 9 14 9H16.75V22.25C16.75 22.66 17.09 23 17.5 23C17.91 23 18.25 22.66 18.25 22.25V9H21C21.55 9 22 8.55 22 8V3C22 2.45 21.55 2 21 2ZM20.15 5.85L18.87 7.14C18.56 7.46 18.02 7.23 18.02 6.79V6.25H15.26C14.85 6.25 14.51 5.91 14.51 5.5C14.51 5.09 14.85 4.75 15.26 4.75H18.02V4.21C18.02 3.76 18.56 3.54 18.87 3.86L20.15 5.15C20.34 5.34 20.34 5.66 20.15 5.85Z"
          fill={color}
          //fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};

export default FollowTheSignsRounded

