import React from "react";

const ContentPasteRounded = ({color='black'}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ContentPasteRounded">
        <path
          id="Vector"
          d="M19 2H14.82C14.4 0.84 13.3 0 12 0C10.7 0 9.6 0.84 9.18 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM12 2C12.55 2 13 2.45 13 3C13 3.55 12.55 4 12 4C11.45 4 11 3.55 11 3C11 2.45 11.45 2 12 2ZM18 20H6C5.45 20 5 19.55 5 19V5C5 4.45 5.45 4 6 4H7V5C7 6.1 7.9 7 9 7H15C16.1 7 17 6.1 17 5V4H18C18.55 4 19 4.45 19 5V19C19 19.55 18.55 20 18 20Z"
          fill={color}
          //fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};

export default ContentPasteRounded;

