import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../button';
import styles from './iconButton.module.scss';

const IconButton = ({onClick, children, className, variant='link'}) => {
    return (
        <Button 
            variant={variant} 
            onClick={onClick} 
            className={clsx(styles.iconButton, className,
            {
            [styles.secondary]: variant === "secondary",
            [styles.rounded_support]: variant === "rounded_support"
        })}>{children}</Button>
    )
}

IconButton.propTypes = {
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(["secondary", "link", "rounded_support"])
}
export default IconButton;
