import React from 'react';
import PropTypes from 'prop-types';
import styles from './optionLinkProfile.module.scss';
import IconButton from '../iconButton';
import ChevronRight from '../../styles/icons/ChevronRightRounded';
import clsx from 'clsx';

const OptionLinkProfile = ({IconOption,textOption,linkOption, handleClick, noBorderBottom}) => {

    return (
        <a 
            href={linkOption}
            className={styles.link_option}
            onClick={handleClick}>
            <div className={clsx(styles.container_option, {
                [styles.border_bottom_less]: noBorderBottom
            })}>
                <p className={styles.number_data}>
                    <IconButton>{IconOption}</IconButton>
                    {textOption}
                </p>
                <IconButton><ChevronRight color='#737373'/></IconButton>
            </div>
        </a>
    
    )
}

OptionLinkProfile.propTypes = {
    IconOption: PropTypes.element,
    textOption: PropTypes.string,
    linkOption: PropTypes.string,
    handleClick: PropTypes.func,
    noBorderBottom: PropTypes.bool
}

export default OptionLinkProfile;