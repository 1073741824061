import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { api } from "../../config/v1Api";
import ConferenceCard from "../../components/conferenceCard";
import CalendarMonthRounded from "../../styles/icons/calendarMonthRounded";
import ContentPasteRounded from "../../styles/icons/ContentPasteRounded";
import StoreRounded from "../../styles/icons/StoreRounded";
import MapRounded from "../../styles/icons/MapRounded";
import Publicity from "../../components/publicity/publicity";
import InfoWithButton from "../../components/infoWithButton/InfoWithButton";
import Toast from "../../components/toast";
import Plate from "../../components/plate/plate";
import Modal from "../../components/modal";
import Button from "../../components/button";
import Spinner from "../../components/spinner/spinner";
import useLocalStorage from "../../hooks/useLocalSorage";
import { useAuthContext } from "../../context/authContext";

import styles from "./event.module.scss";



const EventProfile = () => {
  const [addPublicity, setAddPublicity] = useState('');
  const [portalPublicity, setPortalPublicity] = useState(null);
  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [hasSurveys, setHasSurveys] = useState(false);
  const location = useLocation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const session = useAuthContext();
  const [newsOneTime, setValue] = useLocalStorage('newsOneTime')

  const isVisitor = location.pathname.includes("public");

  const handleConferenceCardCTA = (conferenceId) => {
    navigate(`${location.pathname}/talk/${conferenceId}`);
  };

  useEffect(() => {
    const containerAddPublicity = document.getElementById("portalForPublicity");
    if (containerAddPublicity) {
      setPortalPublicity(containerAddPublicity);
    }
    (async () => {
      try {
        const ad = await api.get(`ad/random/${eventId}`);
        setAddPublicity(ad.data?.imageUrl || '');
      } catch(e) {}
  })()
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let eventPayload = [];
        if (isVisitor) {
          eventPayload = await api.get(`/events/${eventId}`);
        } else {
          eventPayload = await api.get(`/events/${eventId}`);
        }

        setEvent(eventPayload.data);
        setIsLoading(false);

        if (session.user) {
          const surveys = await api.get(`/survey/master/${eventId}`,{ Authorization: `Bearer ${session.user}` });
         
           setHasSurveys(surveys.data.length > 0);
        }
        
        
      } catch (e) {
        console.log("Fetch Event Error***", e);
      }
    })();
  }, [eventId]);

  const handleClickStoreCTA = () => {
    //Agregar CTA button
    navigate(`${location.pathname}/catalogue`);
  };

  const handleClickSurveyCTA = () => {
    //Agregar CTA button
    navigate(`/app/surveys/${eventId}?redirect_url=${location.pathname}`);
  }

  const closeNewsModal = () => {
    setIsModalOpen(false)
    if (!newsOneTime) {
       setValue('watch');
     }
  }

  if (isLoading) {
    return <Spinner />;
  }
  
  return (
    <>
    {!newsOneTime ? 
      <Modal position="center" isOpen={isModalOpen}>
        <h4 className={styles.messageTitle}>¡Bienvenido a Uevent!</h4>
        <div className={styles.modalContainer}>
           <div>
            <p className={styles.usTitle}>Nosotros nos encargamos de:</p>
            <ul className={styles.featureList}>
              <li>Tomar asistencia automáticamente</li>
              <li>Calcular los puntos adquiridos en cada uno de los eventos y/o charlas</li>
              <li>Acumular tus puntos en tú perfil</li>
            </ul>
            <p>Tú encargate de aprender, socializar y disfrutar!</p>
           </div>
          <Toast
            type="warning"
            message="Este evento tiene una puntuación académica. En tú perfil prodrás ver el total de puntos acumulados."
          />
          <Button
            onClick={closeNewsModal}
            className={styles.btnClose}
            variant="secondary"
          >
            Cerrar
          </Button>
        </div>
      </Modal>
      : null }
      <h3 className={styles.title}>{event.title}</h3>
      <p className={styles.description}>{event.description}</p>
      <div className={styles.dateAndPlaceContainer}>
        <Plate
          label="Fecha del evento"
          icon={<CalendarMonthRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"} />}
          text={event.startTime}
        />
         <Plate
          label="Lugar del evento"
          icon={<MapRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"} />}
          text={event.address}
        />
      </div>
      {
        hasSurveys ?
        <InfoWithButton
        onClick={handleClickSurveyCTA}
        Icon={() => <ContentPasteRounded color="#062973" />}
        message="Tienes encuesta(s) disponible, favor de llenar"
        buttonText="Ver encuestas"
      /> : null
      }
      
      {event.store ? (
        <InfoWithButton
          onClick={handleClickStoreCTA}
          Icon={() => <StoreRounded color="#062973" />}
          message="En este evento también estaremos vendiendo souvenirs"
          buttonText="Ver catálogo"
        />
      ) : null}
      {event?.conferenceSubjects?.map((conferenceSubject, idx) => {
        return (
          <div  key={`${conferenceSubject.id}-c`}>
            <div className={clsx(styles.form_group, styles.form_spacing)}>
              <label className={styles.form_label}>
                Selecciona un módulo
              </label>
              <select className={styles.form_select}>
                <option key={conferenceSubject.id}>
                  {conferenceSubject.title}
                </option>
              </select>
            </div>
            <h5
              className={styles.conference_subject}
              key={conferenceSubject.id}
            >
              {conferenceSubject.title}
            </h5>

            {conferenceSubject.conferences.map((conference) => {
              const subscribedCounter =
                conference._count.usersSubscribeConferences;
              return (
                <ConferenceCard
                  key={`${conference.id}-co`}
                  {...conference}
                  subscribedCounter={subscribedCounter}
                  onClickNavigate={() => handleConferenceCardCTA(conference.id)}
                />
              );
            })}
          </div>
        );
      })}

      {addPublicity &&
        portalPublicity &&
        createPortal(
          <Publicity
            imagePublicity={addPublicity}
            textPublicity="Publicidad banner"
            linkPublicity="/linkpublicity"
          />,
          portalPublicity
        )}
    </>
  );
};

export default EventProfile;
