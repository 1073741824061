import React from 'react';
import PropTypes from "prop-types";
import Button from '../button';

import styles from './infoWithButton.module.scss';

const InfoWithButton = ({Icon, onClick, message, buttonText='Text'}) => {
    return (
        <div className={styles.container}>
        <p className={styles.message}>{message}</p>
        <Button
          variant="support"
          iconPosition="right"
          icon={<Icon />}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
    )
}

InfoWithButton.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onClick: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string
}

export default InfoWithButton;