import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../../context/authContext";
import styles from "./login.module.scss";
import Button from "../../../components/button";
import IconButton from "../../../components/iconButton";
// import Google from './../../../styles/images/google.png';
// import Facebook from './../../../styles/images/facebook.png';
// import ErrorRounded from './../../../styles/icons/ErrorRounded';
import FollowTheSignsRounded from "../../../styles/icons/FollowTheSignsRounded";
import ueventIcon from "../../../styles/images/ueventUicon.jpg";
import { useRollbar } from "@rollbar/react";
import { api } from "../../../config/v1Api";
import Const from "./../../../const/const";

const Login = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [validity, setValidity] = useState({ email: true });
  const [searchParams] = useSearchParams();
  const auth = useAuthContext();
  const navigate = useNavigate();
  const rollbar = useRollbar();
  const redirectPath = searchParams.get("redirect_url") || "/app/my-events";

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "email") {
      setValidity((prevValidity) => ({
        ...prevValidity,
        email: Const.EMAIL_REGEX.test(value),
      }));
    }
  };

  const handleLogin = async () => {
    try {
      const {
        email, //, password
      } = form;

      if (email) {
        const response = await api.post("/user/auth", {
          email,
          password: "1234",
        });

        if (response.data.email && response.data.isVerify) {
          auth.login(response.data.email);
          navigate(redirectPath, { replace: true });
        } else {
          rollbar.error("La contraseña no coincide con la del backend");
        }
      } else {
        setValidity((prevValidity) => ({
          ...prevValidity,
          email: false,
        }));
        rollbar.error("Datos de inicio de sesión incompletos");
      }
    } catch (error) {
      setValidity((prevValidity) => ({
        ...prevValidity,
        email: false,
      }));
      rollbar.error("Error al intentar iniciar sesión", error);
    }
  };

  const handleInviteUser = () => {
    navigate("/app/public/events", { replace: true });
  };

  const navigateToRegister = () => {
    navigate("/app/registrar");
  };

  return (
    <div className={styles.container_login}>
      <img src={ueventIcon} className={styles.companyIcon} alt="Logo Uevent" />
      <div className={styles.welcome_text}>
        <h1 className={styles.login_title}>
          Estamos felices de tenerte con nosotros{" "}
          
            <IconButton className={styles.guestButton} onClick={handleInviteUser}>
            <FollowTheSignsRounded color="#062973"/>
            </IconButton>
                  </h1>
        <p className={styles.paragraph}>
          Por favor, inicia sesión para continuar
        </p>
      </div>
      <div className={styles.form_group}>
        <label className={styles.form_label}>Correo electrónico</label>
        <input
          className={styles.form_input}
          type="email"
          name="email"
          value={form.email}
          onChange={handleInputChange}
        />
        {!validity.email && (
          <p className={styles.error_inputmessage}>
            Correo electrónico no válido
          </p>
        )}
      </div>
      {/*
            <div className={styles.form_group}>
                <label className={styles.form_label}>Contraseña</label>
                <input 
                    className={styles.form_input} 
                    type="password"
                    name="password"
                    value={form.password}
                    onChange={handleInputChange} 
                />
            </div>
            */}
      {/*
            <div className={styles.forgot_password}>
                <Link to='/' className={styles.forgot_link}>¿Has olvidado tu contraseña?</Link>
                <label>
                    <input 
                        type="checkbox"
                        name="rememberMe"
                        checked={form.rememberMe}
                        onChange={handleInputChange} />
                    Recuérdame
                </label>
            </div>
            */}
      <Button
        className={styles.submit}
        variant="primary"
        fullWidth
        onClick={handleLogin}
      >
        Ingresar
      </Button>
      <Button
        variant="secundary"
        fullWidth
        onClick={navigateToRegister}
        className={styles.guest}
      >
        Crear una cuenta
      </Button>

{/*
      <Button
        variant="link"
        fullWidth
        onClick={handleInviteUser}
        className={styles.guest}
      >
        Seguir como invitado
      </Button>
      */}
      {/*
            <div className={styles.login_options}>
                <p>O también puedes ingresar con</p>
                <Button 
                    variant='secondary' 
                    fullWidth 
                    className={styles.google_btn}>
                        <img src={Google} alt="Google" className={styles.icon_brand} />
                        Google
                </Button>
                <Button 
                    variant='secondary'
                    fullWidth 
                    className={styles.facebook_btn}>
                        <img src={Facebook} alt="Facebook" className={styles.icon_brand} />
                        Facebook
                </Button>
            </div>
            */}
    </div>
  );
};

export default Login;
