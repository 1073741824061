import React from 'react';
import PropTypes from 'prop-types';
//import IconButton from '../iconButton';
//import ShoppingCartRounded from '../../styles/icons/ShoppingCartRounded';
import styles from './catalogueItem.module.scss';

const CatalogueItem = ({
    name,
    imageUrl,
    totalQuantity,
    price
}) => {
    return <div className={styles.container}>
        <img 
          src={imageUrl}
          alt="articulo de venta"
          className={styles.photo}
        />
        <div className={styles.infoContainer}>
            <h5 className={styles.name}>{name}</h5>
            <p className={styles.quantity}>{`${totalQuantity} cantidades disponibles`}</p>
            <div className={styles.priceContainer}>
                <h5 className={styles.price}>{`${price} DOP`}</h5>
                {/*<IconButton variant='rounded_support'><ShoppingCartRounded/></IconButton>*/}
            </div>

        </div>
    </div>
}

CatalogueItem.propTypes = {
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    totalQuantity: PropTypes.number,
    price: PropTypes.string
}
export default CatalogueItem;
