import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import clsx from "clsx";
import styles from "./speakerProfile.module.scss";
import IconButton from "../../components/iconButton";
import ThumbUpIcon from "../../styles/icons/ThumbUp";
import SocialMediaCard from "../../components/socialMediaCard/socialMediaCard";
//import ResourceItem from "../../components/resourceItem/ResourceItem";
import { api } from "../../config/v1Api";
import Toast from "../../components/toast";
import useLocalStorage from "../../hooks/useLocalSorage.js";
import ModalRedirect from "../../components/modalRedirect/modalRedirect";

import SpeakerCardTalk from "../../components/speakerCardTalk/speakerCardTalk";

const SpeakerProfile = () => {
  const socialMedia = {
    social: [
      {
        title: "Instagram",
        type: "instagram",
      },
      {
        title: "LinkedIn",
        type: "linkedin",
      },
      {
        title: "Sitio web",
        type: "web",
      },
      {
        title: "X",
        type: "x",
      },
    ],
  };

  const [speaker, setSpeaker] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [socialDetail] = useState(socialMedia);

  const { speakerId } = useParams();
  const [sessionToken] = useLocalStorage("user");
  const navigate = useNavigate();
  const location = useLocation();

  const isVisitor = location.pathname.includes("public");
  const modalRef = useRef();

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.openModal();
    }
  };

  const handleClickLikeButton = async () => {
    if (sessionToken) {
      const voteOption = !speaker.vote;
      setSpeaker((prev) => {
        return {
          ...prev,
          vote: !prev.vote,
          speakerVotes: !prev.vote
            ? prev.speakerVotes + 1
            : prev.speakerVotes - 1,
        };
      });

      try {
        //Aqui va logica de api con debounce
           postLike(voteOption);
      } catch (e) {
        console.log("error like***", e);
      }
    } else {
      handleOpenModal();
    }
  };

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const postLike = useCallback(
    debounce(async (voteOption) => {
      await api.post(
        "/speaker/like",
        {
          speakerId,
          vote: voteOption,
        },
        { Authorization: `Bearer ${sessionToken}` }
      );
    }, 900),
    []
  );

  useEffect(() => {
    (async () => {
      try {
        let payload;

        if (isVisitor) {
          payload = await api.get(`/speaker/public/${speakerId}`);
        } else {
          payload = await api.get(`/speaker/${speakerId}`, {
            Authorization: `Bearer ${sessionToken}`,
          });
        }
        
        const { _count, speakerVotes=[], ...profile } = payload.data;
     
        setSpeaker({ ...profile, speakerVotes: _count.speakerVotes, vote: speakerVotes.length > 0 });
      } catch (e) {
        navigate(`/404/?redirect_url=${location.pathname}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [speakerId]);

  if (isLoading) {
    return <p>Is loading</p>;
  }
  return (
    <>
      <ModalRedirect ref={modalRef} modalText="Favor de iniciar session" />
      <div className={styles.header_speaker}>
        <div className={styles.content_header}>
          <img
            className={styles.photo_speaker}
            src={speaker.imageUrl}
            alt="Speaker name"
          />
          <div className={styles.content_detail_speaker}>
            <div className={styles.title_speaker}>
              <h1
                className={styles.name_speaker}
              >{`${speaker.name} ${speaker.familyName}`}</h1>
              <div>
                <span className={styles.likesCounter}>
                  {speaker.speakerVotes}
                </span>
                <IconButton variant="rounded_support" onClick={handleClickLikeButton}>
                  <ThumbUpIcon
                    color={`${
                      speaker.vote > 0 ? "#062973" : "#FFFFFF"
                    }`}
                    fillOpacity={0.5}
                  />
                </IconButton>
              </div>
            </div>
            <p className={styles.mail_speaker}>{speaker.email}</p>
            <p className={styles.description_speaker}>
              Experto en medicina con décadas de experiencia clínica y liderazgo
              en investigación. Apasionado por la divulgación científica y la
              educación médica continua.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.content_card_flex}>
          {socialDetail.social.map((social, idx) => (
            <SocialMediaCard
              key={`ss-${social.title}`}
              title={social.title}
              type={social.type}
            />
          ))}
        </div>

        <div className={clsx(styles.content_documents)}>
          <h5 className={styles.subTitle}>Documentos</h5>
          <Toast
            type="info"
            message="Aquí podra encontrar materiales que el expositor le gustaria compartir."
          />
        </div>

        <div className={clsx(styles.content_talks)}>
          <h5 className={styles.subTitle}>Charlas realizadas</h5>
          {speaker.speakersOnConferences.map((talk) => {
            return (
            <SpeakerCardTalk
              key={talk.conference.id}
              titleTalk={talk.conference.title}
              descriptionTalk={talk.conference.description}
              dateTalk={talk.conference.startDate}
              nameEvent={talk?.conference?.event.title}
            />
          )})}
        </div>
      </div>
    </>
  );
};

export default SpeakerProfile;
