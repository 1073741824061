import { Navigate, useLocation, Outlet } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalSorage";

const PrivateRouter = ({ component: Component, ...rest }) => {
  const [secret] = useLocalStorage("user");
  const location = useLocation();

  if (!secret) {
    return (
      <Navigate
        to={{
          pathname: "/app/login",
          search: `redirect_url=${location.pathname}`,
        }}
        replace
      />
    );
  }

  return <Outlet />;
};

export default PrivateRouter;
