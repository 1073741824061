import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./button.module.scss";

const Button = ({
  variant = 'primary',
  onClick,
  children,
  className,
  icon,
  iconPosition,
  fullWidth,
  disabled
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(styles.btn, className, {
        [styles.primary]: variant === "primary",
        [styles.secondary]: variant === "secondary",
        [styles.link]: variant === "link",
        [styles.support]: variant === "support",
        [styles.full_width]: fullWidth
      })}
      disabled={disabled}
    >
      {iconPosition === "left" && icon ? (
        <span className={styles.iconLeft}>{icon}</span>
      ) : null}
      {children}
      {iconPosition === "right" && icon ? (
        <span className={styles.iconRight}>{icon}</span>
      ) : null}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "link", "support", "rounded_support"]),
  label: PropTypes.string,
  onClick: PropTypes.func,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Button;
