import React, { useEffect, useState, useCallback, useRef } from "react";
import styles from "./questionsList.module.scss";
import Button from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import FavoriteBorderRounded from "../../styles/icons/FavoriteBorderRounded.jsx";
import FavoriteRounded from "../../styles/icons/FavoriteRounded";
import ArrowBackRounded from "../../styles/icons/ArrowBackRounded.jsx";
import QuestionCard from "../../components/questionCard/questionCard.jsx";
import Modal from "../../components/modal/index.jsx";
import IconButton from "../../components/iconButton/index.jsx";
import clsx from "clsx";
import { api } from "../../config/v1Api.js";
import debounce from "lodash.debounce";
import AddCircleIcon from "../../styles/icons/AddCircleIcon";
import useLocalStorage from "../../hooks/useLocalSorage.js";
import Spinner from "../../components/spinner/spinner.jsx";
import ModalRedirect from "../../components/modalRedirect/modalRedirect";
import ErrorRoundedIcon from "../../styles/icons/ErrorRounded.jsx";
import RefreshRounded from "../../styles/icons/RefreshRounded";

const QuestionsList = ({ questions }) => {
  const [showQuestion, setShowQuestion] = useState({
    id: "",
    question: "",
    vote: false,
    questionId: "",
  });
  const [talkDetail, setTalkDetail] = useState({});
  const [questionsList, setQuestionsList] = useState([{ id: 0, question: "" }]);
  const [TALK_STATE, SET_TALK_STATE] = useState("init");
  const [sessionToken] = useLocalStorage("user");
  const [isQuestionLoading, setIsQuestionLoading] = useState(false);
  const navigate = useNavigate();
  const modalRef = useRef();
  const { talkId } = useParams();

  const openModalQuestion = (id, question) => {
    setShowQuestion({ id, question });
  };

  const closeModalQuestion = () => {
    setShowQuestion({ id: "", question: "" });
  };

  const navigateBack = () => navigate(-1);

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.openModal();
    }
  };

  const giveLikeToQuestion = (voteOption, questionId) => {
    if (sessionToken) {
      postQuestionLike(voteOption, questionId);
      closeModalQuestion();
    } else {
      handleOpenModal();
    }
  };

  const postQuestionLike = useCallback(
    debounce(async (voteOption, questionId) => {
      try {
        await api.post(
          "/conference/question/vote",
          {
            questionId,
            vote: voteOption,
          },
          { Authorization: `Bearer ${sessionToken}` }
        );
        getQuestionList();
      } catch (e) {
        console.log("EU**", e);
      }
    }, 900),
    []
  );

  const getQuestionList = useCallback(
    debounce(async () => {
      try {
        setIsQuestionLoading(true);
        if (sessionToken) {
          const questionsPayload = await api.get(
            `/conference/questions/${talkId}`,
            { Authorization: `Bearer ${sessionToken}` }
          );

          const q = questionsPayload.data.map((question) => ({
            ...question,
            vote: question.votes.length > 0 ? question.votes[0].vote : false,
          }));

          setQuestionsList(q);
        } else {
          const questionGuest = await api.get(
            `/conference/questions/guest/${talkId}`
          );
          const qGuest = questionGuest.data.map((question) => ({
            ...question,
            vote: false,
          }));

          setQuestionsList(qGuest);
        }
        setIsQuestionLoading(false);
      } catch (e) {}
    }, 900),
    []
  );

  useEffect(() => {
    (async () => {
      try {
        /*
        const payload = await api.get(`/conference/${talkId}`);
        let currentVote = false;
        if (payload.data.votes.length) {
          currentVote = payload.data.votes[0].vote;
        }
        const voteTalkCount = payload.data._count.votes;
        setTalkDetail({ ...payload.data, vote: currentVote, voteTalkCount });
        const modifedQuestionList = payload.data.conferenceQuestoin.map((question, index) =>({
          ...question,
          id: index +1
        })).reverse();
        setQuestionsList(modifedQuestionList);
        SET_TALK_STATE("feched");

        */
        setIsQuestionLoading(true);

        if (sessionToken) {
          const questionsPayload = await api.get(
            `/conference/questions/${talkId}`,
            { Authorization: `Bearer ${sessionToken}` }
          );

          const q = questionsPayload.data.map((question) => ({
            ...question,
            vote: question.votes.length > 0 ? question.votes[0].vote : false,
          }));

          setQuestionsList(q);
        } else {
          const questionGuest = await api.get(
            `/conference/questions/guest/${talkId}`
          );
          const qGuest = questionGuest.data.map((question) => ({
            ...question,
            vote: false,
          }));

          setQuestionsList(qGuest);
        }
        setIsQuestionLoading(false);
        const confereceMeta = await api.get(
          `/conference/conference/metadata/${talkId}`
        );

        setTalkDetail({ title: confereceMeta.title });
      } catch (e) {
        console.log("SOMETHING HAPPNE***", e);
      }
    })();
  }, [talkId]);

  if (isQuestionLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <ModalRedirect ref={modalRef} modalText="Favor de iniciar session" position="top" />
      <h1 className={styles.title}>{talkDetail.title}</h1>
      <h5 className={clsx(styles.subTitle, styles.questions_length)}>
        preguntas para esta charla {isQuestionLoading ? (
            <ErrorRoundedIcon />
          ) : (
            <IconButton
              onClick={getQuestionList}
              className={styles.button_refresh}
            >
              <RefreshRounded color="#062973" />
            </IconButton>
          )}
      </h5>
      {questionsList.map((element, idx) => (
        <QuestionCard
          key={element.id}
          indexQuestion={questionsList.length - idx}
          question={element.question}
          onClick={openModalQuestion}
          likes={`${element?._count?.votes} Me gusta`}
          vote={element.vote}
          questionId={element.id}
        />
      ))}
      <Modal isOpen={showQuestion.id !== ""} closeModal={closeModalQuestion}>
        <div className={styles.content_modal}>
          <h4 className={styles.title_modal}>Pregunta #{showQuestion.id}</h4>
          {<p>{showQuestion.question}</p>}
          <div className={styles.actions_modal}>
            <IconButton
              onClick={() =>
                giveLikeToQuestion(!showQuestion.vote, showQuestion.questionId)
              }
              variant="secondary"
              className={styles.button_icon}
            >
              {showQuestion.vote && sessionToken ? (
                <FavoriteRounded color="#062973" />
              ) : (
                <FavoriteBorderRounded color="#062973" />
              )}
            </IconButton>

            <Button variant="primary" onClick={closeModalQuestion}>
              Volver
            </Button>
          </div>
        </div>
      </Modal>
      <Button
        variant="primary"
        icon={<ArrowBackRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#062973' : "#FFFFFF"} />}
        iconPosition="left"
        fullWidth
        onClick={navigateBack}
      >
        Volver a la charla
      </Button>
    </div>
  );
};

export default QuestionsList;
