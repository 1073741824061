import React from 'react';
import {
    useNavigate,
  } from "react-router-dom";
  import { useAuthContext } from "../../context/authContext";
import IconButton from '../iconButton';
import HomeRounded from '../../styles/icons/homeRoundedIcon';
import styles from './footer.module.scss';

const Footer = () => {
    const navigate = useNavigate();
    const session = useAuthContext();

    const handleGoHome = () => {
        if (session.user) {
            navigate('/app/my-events');
        } else {
            navigate('/app/public/events'); 
        }
        
    }
    return (
        <>
            <div className={styles.container}>
                <IconButton onClick={handleGoHome}>
                    <HomeRounded color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"}/>
                </IconButton>
            </div>
        </>
    )
}

export default Footer;