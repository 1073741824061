import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../context/authContext";

export const LoggedinInUsers = ({children}) =>{
    const location = useLocation();
    const auth = useAuthContext();

    if(auth.user) {
        return(
            <Navigate
                to='/app/my-events'
                state={{path: location.pathname}}
            />
        ) 
    }

    return children
}