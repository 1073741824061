import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../modal';
import styles from './modalRedirect.module.scss';
import Button from '../button';
import useLocalStorage from '../../hooks/useLocalSorage';
import PropTypes from 'prop-types';

const ModalRedirect = forwardRef(({modalText, position='bottom'}, ref) => {
    const [user] = useLocalStorage("user");
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal: () => {
            if (!user) {
                setIsOpen(true);
            }
        },
        closeModal: () => setIsOpen(false)
    }));

    const suscribeMe = () => {
        if (!user) {
            navigate(`/app/login?redirect_url=${location.pathname}`);
        }
    }

    const closeModalRedirect = () => {
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModalRedirect}
            position={position}>
            <div className={styles.content_modal}>
                <h4 className={styles.title_modal}>
                    {modalText}
                </h4>
                <div className={styles.actions_modal}>
                    <Button
                        variant="support"
                        onClick={suscribeMe}>
                        Iniciar sesión
                    </Button>
                </div>
            </div>
        </Modal>
    );
});

ModalRedirect.propTypes = {
    modalText: PropTypes.string
}

export default ModalRedirect;