import React from 'react';
import PropTypes from 'prop-types';
import styles from './profileCardData.module.scss';

const ProfileCardData = ({numberData,typeData}) => {

    return (
    <div className={styles.container_card}>
        <h3 className={styles.number_data}>{numberData}</h3>
        <p className={styles.type_data}>{typeData}</p>
    </div>
    )
}

ProfileCardData.propTypes = {
    numberData: PropTypes.number,
    typeData: PropTypes.string
}

export default ProfileCardData;