import React from 'react';
import PropTypes from 'prop-types';
import PdfRoundIcon from '../../styles/icons/PdfRoundIcon';
import FileRoundedIcon from '../../styles/icons/FileRoundedIcon';
import LinkRoundedIcon from '../../styles/icons/LinkRoundedIcon';
import ShareRoundedIcon from '../../styles/icons/ShareRoundedIcon';
import RemoveRedEyeRoundedIcon from '../../styles/icons/RemoveRedEyeRoundedIcon';
import DownloadRoundedIcon from '../../styles/icons/DownloadRoundedIcon';
import styles from './resourceItem.module.scss';
import IconButton from '../iconButton';
import clsx from "clsx";

const ResourceItem = ({title, type}) => {
    let resourceType = <FileRoundedIcon color="#FFAA31"/>;
    switch(type) {
        case 'pdf':
            resourceType = <PdfRoundIcon color="#DB2730"/>;
            break;
        case 'zip':
            resourceType = <FileRoundedIcon color="#FFAA31"/>;
            break;
        case 'link':
            resourceType = <LinkRoundedIcon color="#0641C2"/>;
            break;
        default:
            resourceType = <FileRoundedIcon color="#FFAA31"/>;
            
    }
    return (
        <div className={styles.cont_card}>
            <div className={styles.title_type}>
                <div className={styles.icon_type}>{resourceType}</div>
                <span 
                    className={clsx(  
                        styles.name_elipsis
                      )}>
                    {title}
                </span>
            </div>
            <div>
                {type === 'zip' ? null : (
                    <IconButton>
                        <RemoveRedEyeRoundedIcon color='#062973'/>
                    </IconButton>
                )}
                <IconButton>
                    <ShareRoundedIcon color='#062973'/>
                </IconButton>
                {type === 'link' ? null : (
                    <IconButton>
                        <DownloadRoundedIcon color='#062973' />
                    </IconButton>
                )}
            </div>
        </div>
    )
}

ResourceItem.propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['pdf','link','zip'])
}

export default ResourceItem;