import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../../config/v1Api";
import { useRollbar } from "@rollbar/react";
import Const from "./../../../const/const";
import Button from "../../../components/button";
import Spinner from "../../../components/spinner/spinner";
import Toast from "../../../components/toast";

import styles from "./register.module.scss";
const Register = () => {
  const rollbar = useRollbar();
  const [form, setForm] = useState({
    email: "",
    name: "",
    familyName: "",
    phoneNumber: "",
    ig: "",
    webSite: "",
    linkedId: ""
  });
  const [validity, setValidity] = useState({ email: true });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "email") {
      setValidity((prevValidity) => ({
        ...prevValidity,
        email: Const.EMAIL_REGEX.test(value),
      }));
    }
  };

  const submit = async () => {
    try {
      try {
        setIsLoading(true);
        const { email, name, familyName, phoneNumber } = form;
        if (email && name && familyName && phoneNumber) {
            await api.post('/user',{
                name,
                familyName,
                email,
                phoneNumber,
            });
            setCompleted(true);
        } else {
            setError('Los campose con (*) son obligatorios')
          setValidity((prevValidity) => ({
            ...prevValidity,
            email: false,
          }));
          rollbar.error("Datos de inicio de sesión incompletos");
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log("ERROR***", e.code, e.mmessage, e)
        setError('Error al guardar el formulario o el corre ya existe')
      }
    } catch (e) {}
  };

  const goToLogin = () => {
    navigate('/app/login')
  }

  if (completed) {
    return (
    <div className={styles.container}>
        <h4>
            Gracias por registrate en Uevent.
        </h4>
        
        <Button onClick={goToLogin} variant="link" className={styles.go_login}>Click aquí para ir a la página de inicio</Button>
    </div>)
  }
  return (
    <div className={styles.container}>
        {isLoading ? <Spinner/> : null}
        <Button onClick={goToLogin} variant="link" className={styles.go_login}>Ir a Login</Button>
      <h1 className={styles.login_title}>
        Formulario de nuevo usuario.
      </h1>
      {error ? <Toast type="error" message={error}/> : null}
      <div className={styles.form_group}>
        <label className={styles.form_label}>Nombre*</label>
        <input
          className={styles.form_input}
          type="text"
          name="name"
          value={form.name}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.form_group}>
        <label className={styles.form_label}>Apellidos*</label>
        <input
          className={styles.form_input}
          type="text"
          name="familyName"
          value={form.familyName}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.form_group}>
        <label className={styles.form_label}>Correo electrónico*</label>
        <input
          className={styles.form_input}
          type="email"
          name="email"
          value={form.email}
          onChange={handleInputChange}
        />
        {!validity.email && (
          <p className={styles.error_inputmessage}>
            Correo electrónico no válido
          </p>
        )}
      </div>
      <div className={styles.form_group}>
        <label className={styles.form_label}>Teléfono(sin guion)*</label>
        <input
          className={styles.form_input}
          type="text"
          name="phoneNumber"
          value={form.phoneNumber}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.form_group}>
        <label className={styles.form_label}>Instagram</label>
        <input
          className={styles.form_input}
          type="text"
          name="ig"
          value={form.ig}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.form_group}>
        <label className={styles.form_label}>LinkedId</label>
        <input
          className={styles.form_input}
          type="text"
          name="linkedIn"
          value={form.linkedIn}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.form_group}>
        <label className={styles.form_label}>Website</label>
        <input
          className={styles.form_input}
          type="text"
          name="webSite"
          value={form.facebook}
          onChange={handleInputChange}
        />
      </div>
      <Button className={styles.submitBtn} onClick={submit} disabled={isLoading} fullWidth>Crear Usuario</Button>
    </div>
  );
};

export default Register;
