import React from "react";
import PropTypes from 'prop-types';
import IconButton from "../iconButton";
import KeyboardArrowRight from "../../styles/icons/KeyboardArrowRight";
import styles from './speakerDisplay.module.scss';

const SpeakerDisplay = ({ fullName, imageUrl, onClickNavigate }) => {
  return (
    <div className={styles.container}>
      <div className={styles.full_name_container}>
        <img className={styles.image} alt={fullName} src={imageUrl} />
        <p className={styles.full_name}>{fullName}</p>
      </div>
      <IconButton className={styles.btn_arrow} onClick={onClickNavigate}>
        <KeyboardArrowRight color={window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#FFFFFF' : "#062973"} />
      </IconButton>
    </div>
  );
};

SpeakerDisplay.propTypes = {
  fullName: PropTypes.string,
  imageUrl: PropTypes.string,
  onClickNavigate: PropTypes.func
}
export default SpeakerDisplay;
