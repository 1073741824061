import { Link } from "react-router-dom";
import Button from "../../components/button";
const NotFoundPage = () =>{
    return(
        <div>
            <h1>404 Not found</h1>
            <Link to="/">
                <Button variant="link">Ir al inicio</Button>
            </Link>
        </div>
    )
}

export default NotFoundPage;