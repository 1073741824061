import React from "react";

const FileRoundedIcon = ({ color = "black" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="FilePresentRounded">
        <path
          id="Vector"
          d="M13.17 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8.83C20 8.3 19.79 7.79 19.41 7.42L14.58 2.59C14.21 2.21 13.7 2 13.17 2ZM16 15C16 17.34 13.99 19.21 11.61 18.98C9.53 18.78 8 16.92 8 14.83V9.64C8 8.33 8.94 7.14 10.24 7.01C11.74 6.86 13 8.03 13 9.5V14C13 14.55 12.55 15 12 15C11.45 15 11 14.55 11 14V9.5C11 9.22 10.78 9 10.5 9C10.22 9 10 9.22 10 9.5V14.89C10 15.89 10.68 16.81 11.66 16.97C12.92 17.18 14 16.21 14 15V12C14 11.45 14.45 11 15 11C15.55 11 16 11.45 16 12V15ZM14 7V4L18 8H15C14.45 8 14 7.55 14 7Z"
          fill={color}
          //fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};

export default FileRoundedIcon;
